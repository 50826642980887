import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  intervalToDuration,
  parseISO,
} from 'date-fns';

import { ExpiryTimeLeft } from 'src/features/room/components/active-machine-info/active-machine-info.types';

import { pluralize } from '..';

export const calculateExpiryTimeLeft = (start: Date, end: Date): ExpiryTimeLeft => {
  const duration = intervalToDuration({
    start,
    end,
  });

  return {
    days: duration.days ?? 0,
    hours: duration.hours ?? 0,
    minutes: duration.minutes ?? 0,
    seconds: duration.seconds ?? 0,
  };
};

export const formatDate = (dateString: string | undefined) => {
  if (!dateString) return '';
  const date = parseISO(dateString);
  return format(date, 'dd MMM');
};

export const formatFullDate = (dateString: string | undefined) => {
  if (!dateString) return '';
  const date = parseISO(dateString);
  return format(date, 'd MMMM yyyy');
};

export function formatTimeUntil(date: Date) {
  const now = new Date();

  if (date <= now) return { period: 'seconds', text: 'Ended' };

  const diffSeconds = differenceInSeconds(date, now);
  if (diffSeconds < 60) {
    return {
      period: 'seconds',
      text: `${diffSeconds} ${pluralize('second', diffSeconds)}`,
    };
  }

  const diffMinutes = differenceInMinutes(date, now);
  if (diffMinutes < 60) {
    return {
      period: 'minutes',
      text: `${diffMinutes} ${pluralize('minute', diffMinutes)}`,
    };
  }

  const diffHours = differenceInHours(date, now);
  if (diffHours < 24) {
    return {
      period: 'hours',
      text: `${diffHours} ${pluralize('hour', diffHours)}`,
    };
  }

  const diffDays = differenceInDays(date, now);
  return {
    period: 'days',
    text: `${diffDays} ${pluralize('day', diffDays)}`,
  };
}
